import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import store from "./store";
// import { App as AppWrapper } from "antd";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SiteLoader } from "./helpers/utils";
// import { ErrorBoundary } from "react-error-boundary";

// CSS
import "./View/CSS/style.css";
import "./Assets/CSS/custom.css";
import "./Assets/CSS/style.css";
import "./Components/CSS/style.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

const ViewIndex = lazy(() => import("./View/ViewIndex"));
const SplashScreen = lazy(() => import("./View/SplashScreen"));

const Question1 = lazy(() => import("./View/Pages/Question1"));
const Chemotherapy = lazy(() => import("./View/Pages/Chemotherapy"));
const Radiotherapy = lazy(() => import("./View/Pages/Radiotherapy"));
const Immunotherapy = lazy(() => import("./View/Pages/Immunotherapy"));
const Surgery = lazy(() => import("./View/Pages/Surgery"));
const Question3 = lazy(() => import("./View/Pages/Question3"));
const Question4 = lazy(() => import("./View/Pages/Question4"));
const Question5 = lazy(() => import("./View/Pages/Question5"));
const Question1Result = lazy(() => import("./View/Pages/Question1Result"));
const FinalResult = lazy(() => import("./View/Pages/FinalResult"));

root.render(
  <React.StrictMode>
    {/* <AppWrapper> */}
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<SiteLoader />}>
          {/* <ErrorBoundary FallbackComponent={ErrorFallbackPage}> */}
          {/* Route List */}
          <Routes>
            {/* View */}
            <Route path="/" element={<SplashScreen />} />
            <Route path="questions" element={<ViewIndex />}>
              <Route path="question-1">
                <Route path="" element={<Question1 />} />
                <Route path="result" element={<Question1Result />} />
              </Route>

              <Route path="question-2/1" element={<Chemotherapy />} />
              <Route path="question-2/2" element={<Radiotherapy />} />
              <Route path="question-2/3" element={<Immunotherapy />} />
              <Route path="question-2/4" element={<Surgery />} />
              <Route path="question-3" element={<Question3 />} />
              <Route path="question-4" element={<Question4 />} />
              <Route path="question-5" element={<Question5 />} />
              <Route path="final-result" element={<FinalResult />} />
            </Route>

            {/* View */}
          </Routes>
          {/* Route List End */}
          {/* </ErrorBoundary> */}
        </Suspense>
      </BrowserRouter>
    </Provider>
    {/* </AppWrapper> */}
  </React.StrictMode>
);
