import { combineReducers, configureStore } from "@reduxjs/toolkit";

const modalReducer = (
  state = {
    isResetModalOpen: false,
  },
  action
) => {
  switch (action.type) {
    case "OPEN_RESET_MODAL":
      return {
        ...state,
        isResetModalOpen: true,
      };
    case "CLOSE_RESET_MODAL":
      return {
        ...state,
        isResetModalOpen: false,
      };
    default:
      return state;
  }
};

const cancerTypeReducer = (
  state = {
    cancerType: "",
  },
  action
) => {
  switch (action.type) {
    case "SET_CANCER_TYPE":
      return {
        ...state,
        cancerType: action.payload,
      };
    case "RESET_CANCER_TYPE":
      return {
        ...state,
        cancerType: "",
      };
    default:
      return state;
  }
};

const adviceReducer = (
  state = {
    strengthAdvice: "",
    aerobicAdvice: "",
  },
  action
) => {
  switch (action.type) {
    case "SAVE_STRENGTH_ADVICE":
      return {
        ...state,
        strengthAdvice: action.payload,
      };
    case "SAVE_AEROBIC_ADVICE":
      return {
        ...state,
        aerobicAdvice: action.payload,
      };
    case "RESET_ADVICE":
      return {
        ...state,
        strengthAdvice: "",
        aerobicAdvice: "",
      };
    default:
      return state;
  }
};

const confidenceReducer = (
  state = {
    confidence: -1,
  },
  action
) => {
  switch (action.type) {
    case "SAVE_CONFIDENCE":
      return {
        ...state,
        confidence: action.payload,
      };
    case "RESET_CONFIDENCE":
      return {
        ...state,
        confidence: -1,
      };

    default:
      return state;
  }
};

const experienceReducer = (
  state = {
    experience: -1,
  },
  action
) => {
  switch (action.type) {
    case "SAVE_EXPERIENCE":
      return {
        ...state,
        experience: action.payload,
      };
    case "RESET_EXPERIENCE":
      return {
        ...state,
        experience: "",
      };
    default:
      return state;
  }
};

const question2Reducer = (
  state = {
    chemotherapy: "",
    radioTherapy: "",
    immunotherapy: "",
    surgery: "",
  },
  action
) => {
  switch (action.type) {
    case "SET_CHEMOTHERAPY":
      return {
        ...state,
        chemotherapy: action.payload,
      };

    case "SET_RADIOTHERAPY":
      return {
        ...state,
        radioTherapy: action.payload,
      };

    case "SET_IMMUNOTHERAPY":
      return {
        ...state,
        immunotherapy: action.payload,
      };

    case "SET_SURGERY":
      return {
        ...state,
        surgery: action.payload,
      };
    case "RESET_ALL":
      return {
        ...state,
        chemotherapy: "",
        radioTherapy: "",
        immunotherapy: "",
        surgery: "",
      };
    default:
      return state;
  }
};

const store = configureStore({
  reducer: combineReducers({
    advice: adviceReducer,
    confidence: confidenceReducer,
    experience: experienceReducer,
    modal: modalReducer,
    cancerType: cancerTypeReducer,
    question2: question2Reducer,
  }),
});

export default store;
