import React from "react";

export const SiteLoader = ({ text }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        width: "calc(100% - 1rem)",
      }}
    >
      <p className="Loader">
        <div className="d-block">
          <div className="spinner-border" role="status"></div>
        </div>
      </p>
      <p className="ff_md" style={{ fontSize: "1.5rem", lineHeight: "1.1" }}>
        {text !== undefined ? text : "Loading..."}
      </p>
    </div>
  );
};

export const clearStorage = () => {
  localStorage.setItem("cancerType", "");
  localStorage.setItem("strengthAdvice", "");
  localStorage.setItem("aerobicAdvice", "");
  localStorage.setItem("chemotherapy_option", "");
  localStorage.setItem("radiotherapy_option", "");
  localStorage.setItem("immunotherapy_option", "");
  localStorage.setItem("surgery_option", "");
  localStorage.setItem("selectedOption", "");
  localStorage.setItem("selectedOption2", "");
};
